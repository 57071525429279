import { ColorPicker, Tag } from 'antd';
import LocationListComponent from '../location/LocationListComponent';
import ProductStatusListComponent from '../product-status/ProductStatusListComponent';
import PartAlertListComponent from '../part-alert/PartAlertListComponent';

export const SettingsPage = () => {
  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-sm-12">
          <p className="p-head-text">Extraction settings</p>
        </div>
      </div>

      <div className="profile-row-card">
        <div className="row">
          <div className="col-sm-3">
            <p className="s-key-text">Part color</p>
          </div>
          <div className="col-sm-9">
            <ColorPicker size="small" showText />
          </div>
        </div>
      </div>

      <div className="profile-row-card">
        <div className="row">
          <div className="col-sm-3">
            <p className="s-key-text">Linked parts</p>
          </div>
          <div className="col-sm-9">
            <Tag className="s-key-text" color="#F2F4F7">
              SLIDER
            </Tag>
          </div>
        </div>
      </div>

      <div className="profile-row-card">
        <div className="row">
          <div className="col-sm-3">
            <p className="s-key-text">Part auto rotation</p>
          </div>
          <div className="col-sm-9">
            <Tag className="s-key-text" color="#F2F4F7">
              SLIDER
            </Tag>
          </div>
        </div>
      </div>

      <div className="profile-row-card">
        <div className="row">
          <div className="col-sm-3">
            <p className="s-key-text">Include Sinks</p>
          </div>
          <div className="col-sm-9">
            <Tag className="s-key-text" color="#F2F4F7">
              SLIDER
            </Tag>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-sm-12">
          <p className="p-head-text">Print settings</p>
        </div>
      </div>

      <div className="profile-row-card">
        <div className="row">
          <div className="col-sm-3">
            <p className="s-key-text">Notes print by default</p>
          </div>
          <div className="col-sm-9">
            <Tag className="s-key-text" color="#F2F4F7">
              SLIDER
            </Tag>
          </div>
        </div>
      </div>

      <div className="profile-row-card">
        <div className="row">
          <div className="col-sm-3">
            <p className="s-key-text">Part areas</p>
          </div>
          <div className="col-sm-9">
            <Tag className="s-key-text" color="#F2F4F7">
              SLIDER
            </Tag>
          </div>
        </div>
      </div>

      <div className="profile-row-card">
        <div className="row">
          <div className="col-sm-3">
            <p className="s-key-text">Display “printed” tag on printed parts</p>
          </div>
          <div className="col-sm-9">
            <Tag className="s-key-text" color="#F2F4F7">
              SLIDER
            </Tag>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-sm-12">
          <p className="p-head-text">Part Detail Settings</p>
        </div>
      </div>

      <div className="profile-row-card">
        <div className="row">
          <div className="col-sm-12">
            <LocationListComponent />
          </div>
        </div>
      </div>

      <div className="profile-row-card">
        <div className="row">
          <div className="col-sm-12">
            <PartAlertListComponent />
          </div>
        </div>
      </div>

      <div className="profile-row-card">
        <div className="row">
          <div className="col-sm-12">
            <ProductStatusListComponent />
          </div>
        </div>
      </div>
    </div>
  );
};
